import * as THREE from 'three'
import ReactDOM from 'react-dom'
import React, {useState} from 'react'
import {Canvas} from 'react-three-fiber'
import {useSpring, config} from '@react-spring/core'
import {a} from '@react-spring/three'
import {a as aDom} from '@react-spring/web'
import Text from './helpers/Text'
import Scene from './Scene'
import useModel from './helpers/useModel'
import useYScroll from './helpers/useYScroll'
import './helpers/uniforms'
import './styles.css'
import allColors from "./helpers/all-colors";

const material = {transparent: true, roughness: 0.8, fog: true, shininess: 0, flatShading: false}

const Seat = ({color, ...props}) => {
  const [geometries, center] = useModel('/seat.glb')
  const [hovered, set] = useState(false)
  const hover = e => e.stopPropagation() && set(true)
  const unhover = () => set(false)
  const {scale} = useSpring({scale: hovered ? 1.2 : 1, config: config.stiff})
  return (
    <a.group {...props} onPointerOver={hover} onPointerOut={unhover} scale={scale.interpolate(s => [s, s, 1])}>
      {geometries.map(geom => (
        <mesh key={geom.uuid} position={center} geometry={geom} castShadow receiveShadow>
          <meshPhysicalMaterial attach="material" {...material} roughness={1} shininess={0} color={color}/>
        </mesh>
      ))}
    </a.group>
  )
}

const Quarter = ({color, seats, isLeft = false, isRight = false, ...props}) => (
  <group {...props}>

    {seats === 4 && (
      <>
        <Seat color={color} position={[-0.35, 0, 0.7]}/>
        <Seat color={color} position={[0.35, 0, 0.7]}/>
        <Seat color={color} position={[-0.35, 0, -0.7]} rotation={[0, Math.PI, 0]}/>
        <Seat color={color} position={[0.35, 0, -0.7]} rotation={[0, Math.PI, 0]}/>
      </>
    )}

    {isLeft && seats === 2 && (
      <>
        <Seat color={color} position={[-0.35, 0, 0.7]}/>
        <Seat color={color} position={[-0.35, 0, -0.7]} rotation={[0, Math.PI, 0]}/>
      </>
    )}

    {isRight && seats === 2 && (
      <>
        <Seat color={color} position={[0.35, 0, 0.7]}/>
        <Seat color={color} position={[0.35, 0, -0.7]} rotation={[0, Math.PI, 0]}/>
      </>
    )}


  </group>
)

const Row = ({color, rows, seats, ...props}) => (
  <group {...props}>
    <Text color="gray" size={0.15} position={[2.2, 2, 10.8]} rotation={[-Math.PI / 2, 0, 0]} children="1"/>
    <Text color="gray" size={0.15} position={[2.2, 2, 10]} rotation={[-Math.PI / 2, 0, 0]} children="2"/>
    <Text color="gray" size={0.15} position={[-2.2, 2, 10.8]} rotation={[-Math.PI / 2, 0, 0]} children="1"/>
    <Text color="gray" size={0.15} position={[-2.2, 2, 10]} rotation={[-Math.PI / 2, 0, 0]} children="2"/>
    <Quarter color={color} seats={seats} isLeft position={[-1.2, -0.45, 9.75]}/>
    {rows === 2 && (
      <Quarter color={color} seats={seats} isRight position={[1.2, -0.45, 9.75]}/>
    )}
  </group>
)

const Cabin = ({color = 'white', seatColor = 'white', name, rows, seats, ...props}) => {
  const [geometries, center] = useModel('/cabin.glb')
  return (
    <group {...props}>
      <Text centerX={false} color="lightcoral" size={0.6} position={[2.6, 2, 10.6]} rotation={[-Math.PI / 2, 0, 0]}>
        {name}
      </Text>
      <group position={center}>
        {geometries.map((geom, index) => (
          <mesh key={geom.uuid} geometry={geom} castShadow receiveShadow>
            <meshPhysicalMaterial
              attach="material"
              {...material}
              color={index === 0 ? color : 'indianred'}
              opacity={index === 0 ? 1 : 0.2}
            />
          </mesh>
        ))}
      </group>
      <Row color={seatColor} rows={rows} seats={seats}/>
      <Row color={seatColor} rows={rows} seats={seats} position={[0, 0, -1.9]}/>
      <Row color={seatColor} rows={rows} seats={seats} position={[0, 0, -6.6]}/>
      <Row color={seatColor} rows={rows} seats={seats} position={[0, 0, -8.5]}/>
      <Row color={seatColor} rows={rows} seats={seats} position={[0, 0, -11]}/>
      <Row color={seatColor} rows={rows} seats={seats} position={[0, 0, -12.9]}/>
      <Row color={seatColor} rows={rows} seats={seats} position={[0, 0, -17.6]}/>
      <Row color={seatColor} rows={rows} seats={seats} position={[0, 0, -19.5]}/>
    </group>
  )
}

// <Cabin color="white" seatColor="lightskyblue" name="1A" position={[0, 0, -6]}/>
// <Cabin color="lightgray" seatColor="gray" name="2B" position={[0, 0, -32]}/>
// <Cabin color="white" seatColor="lightskyblue" name="3A" position={[0, 0, -58]}/>
// <Cabin color="lightgray" seatColor="gray" name="4B" position={[0, 0, -84]}/>
// <Cabin color="#676767" seatColor="sandybrown" name="5B" position={[0, 0, -110]}/>

const defaultCabin = {
  cabinColor: "white",
  seatColor: "gray",
  name: "",
  rows: 2,
  seats: 4,
}


const cabinName = i => `Cabin-${i}`;

const App = () => {
  const [cabins, setCabins] = useState([{
    ...defaultCabin,
    name: cabinName(1)
  }])

  const handleCabinChangeValue = (field, cabin, fn) => event => setCabins(cabins.map(c => c === cabin ? {
    ...cabin,
    [field]: fn(event.target.value)
  } : c));

  const height = 480 * cabins.length;
  const [y] = useYScroll([-100, height], {domTarget: window})
  return (
    <>
      <div style={{position: 'absolute', left: 0, top: 0, zIndex: 1, margin: `8px 16px`}}>
        {cabins.length > 0 && (


          <table style={{color: 'grey', fontSize: '60%', marginTop: 8}}>
            <thead>
            <tr>
              <th/>
              <th>Cabin Name</th>
              <th>Cabin Color</th>
              <th>Seat Color</th>
              <th>Rows</th>
              <th>Seats</th>
              <th/>
            </tr>
            </thead>
            <tbody>
            {cabins.map((cabin, i) => (
              <tr key={i}>
                <td>{i + 1}.</td>
                <td>
                  <input style={{width: 70}} maxLength="20" type="text" value={cabin.name} onChange={handleCabinChangeValue('name', cabin, val => val)}/>
                </td>
                <td>
                  <select style={{width: 90}} value={cabin.cabinColor} onChange={handleCabinChangeValue('cabinColor', cabin, val => val)}>
                    {allColors.map(color => <option key={color}>{color}</option>)}
                  </select>
                </td>
                <td>
                  <select style={{width: 90}} value={cabin.seatColor} onChange={handleCabinChangeValue('seatColor', cabin, val => val)}>
                    {allColors.map(color => <option key={color}>{color}</option>)}
                  </select>
                </td>
                <td>
                  <select value={cabin.rows} onChange={handleCabinChangeValue('rows', cabin, val => parseInt(val))}>
                    <option value={1}>one</option>
                    <option value={2}>two</option>
                  </select>
                </td>
                <td>
                  <select value={cabin.seats} onChange={handleCabinChangeValue('seats', cabin, val => parseInt(val))}>
                    <option value={2}>two</option>
                    <option value={4}>four</option>
                  </select>
                </td>
                <td>
                  <button onClick={() => setCabins(cabins.filter(c => c !== cabin))}>remove</button>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        )}
        <button style={{marginTop: 8, marginLeft: 16}} onClick={() => setCabins([...cabins, {
          ...defaultCabin,
          name: cabinName(cabins.length + 1),
          cabinColor: allColors[Math.floor(Math.random() * allColors.length)],
          seatColor: allColors[Math.floor(Math.random() * allColors.length)],
        }])}>add cabin
        </button>
      </div>
      <Canvas
        invalidateFrameloop
        camera={{position: [0, 0, 10], fov: 65}}
        gl={{alpha: false}}
        onCreated={({gl, scene}) => {
          scene.background = new THREE.Color('#efefef')
          scene.rotation.set(Math.PI / 4, 0, 0)
          gl.shadowMap.enabled = true
          gl.shadowMap.type = THREE.PCFSoftShadowMap
        }}>
        <Scene>
          <a.group position-z={y.interpolate(y => (y / 500) * 25)}>
            {cabins.map((cabin, i) => (
              <Cabin key={i} color={cabin.cabinColor} seatColor={cabin.seatColor} name={cabin.name} position={[0, 0, (-i * 26) - 6]} rows={cabin.rows} seats={cabin.seats}/>
            ))}
          </a.group>
        </Scene>
      </Canvas>
      {cabins.length > 0 && (
        <aDom.div className="bar" style={{height: y.interpolate([-100, height], ['0%', '100%'])}}/>
      )}
    </>
  )
}

ReactDOM.render(
  <App/>
  , document.getElementById('root'))
